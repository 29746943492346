const fr = {
    "choose a route": "Choisissez un parcours",
    "day": "jour",
    "days": "jour",
    "stops": "arrêts",
    "changing direction to": "Chargement",
    "finding a starting point near you": "Chargement",
    "see what it’s like to take this route": "Voyez à quoi ressemble ce parcours",
    "customize your itinerary": "Personnalisez ce parcours",
    "choose loop direction": "Changez la direction du circuit",
    "clockwise": "Dans le sens horaire",
    "counter-clockwise": "Dans le sens antihoraire",
    'tell us where you"re coming from': "Changez le point de départ",
    "e-mail to myself": "Envoyez-le-moi par courriel",
    "download .gpx": "Téléchargez en .GPX",
    "download": "Téléchargez en",
    "change": "Changer le nom",
    "choose your itinerary": "Choisissez votre parcours",
    "your itinerary": "Votre parcours",
    "cancel": "Annuler",
    "starts in": "Point de départ à",
    "website": "Site web",
    "call": "Téléphone",
    "previous": "Précédent",
    "next": "Suivant",
    "of": "de",
    "routes": "parcours",
    "re-center": "Recentrer",

    // new translations
    "learn more": "En savoir plus",
    "show options": "Show Options",
    "auto-follow": "Auto-follow",
    "download gpx": "Téléchargez en .GPX",
    "download pdf": "Téléchargez en PDF",
    "loop directions": "Changez la direction du circuit",
    "phone": "Téléphone",
    "google maps": "Google Maps",
    "to next stop": "Prochain arrêt",
    "end of itinerary": "Fin du parcours",
    "start of itinerary": "Début du parcours",
    "start": "Début",
    "email favorites to myself": "Envoyez-le-moi par courriel",
    "options": "Options",

    "browse itineraries": "Browse itineraries",
};

const languages = {
    fr: new Proxy(
        {},
        {
            get(target, name) {
                return fr[name.toLowerCase()];
            },
        },
    ),
};

export default languages;
