import Plausible from "plausible-tracker";

const urlParams = new URLSearchParams(window.location.search);
const pathArray = window.location.pathname.split("/");
const typeSegment = pathArray[1] ? pathArray[1] : null;
const idSegment = pathArray[2] ? pathArray[2] : null;
const trprID = urlParams.get("id") || idSegment;
const typeID = urlParams.get("type") || typeSegment || "itinerary";

export default function TrackPageView(appDomain) {
    //Plausible Tracking
    const { trackPageview } = Plausible({
        domain: appDomain,
        trackLocalhost: true,
    });
    trackPageview({ url: `https://go.ontario.app/${typeID}/${trprID}` });
}

export function trackOutboundClick(appDomain, url) {
    const { trackEvent } = Plausible({
        domain: appDomain,
        trackLocalhost: true,
    });

    // Tracks the 'Outbound Click' Goal
    trackEvent(
        "Outbound Link: Click",
        {
            callback: () => console.log("done"),
            props: {
                url: url,
            },
        },
        { trackLocalhost: true, url: `https://go.ontario.app/${typeID}/${trprID}` },
    );
}

export function trackStopInView(appDomain, stopLabel) {
    const { trackEvent } = Plausible({
        domain: appDomain,
        trackLocalhost: true,
    });

    // Tracks the Scroll Depth Goal
    trackEvent(
        "Stop: Scroll in View",
        {
            props: {
                stop: stopLabel,
            },
        },
        { trackLocalhost: true, url: `https://go.ontario.app/${typeID}/${trprID}` },
    );
}
