import React from "react";

function AppHeader() {
    return (
        <div className="trpr__app-header-container">
            <div className="trpr__app-header">
                <i className="trpr__app-header-icon trpr__icon-trip-planner"></i>
            </div>
        </div>
    );
}

export default AppHeader;
